<template>
  <screen_width_cont>
    <div class="cont_300_basico ancho_acotado">
      <div class="newDestinaDisclContainer">
        <div class="newDestinaDisclModal">
          <div class="newDestinaDisclHeader">
            <headerCerrar colorIcon="rojoOscuro" @cancelar="cerrarModal" />
          </div>

          <div class="newDestinaDisclTitle title title_margin_top">
            {{ t("app.cotizadorNuevoDestinatarioDisclaimer.tit") }}
          </div>

          <div class="newDestinaDisclText text_basico f_w_400">
            {{ t("app.cotizadorNuevoDestinatarioDisclaimer.disclaimer") }}
          </div>

          <div class="newDestinaDisclBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.continuar')"
              @action="continuarRegistroDestinatario"
              colorBack="rojo"
              colorText="blco"
              :estadoBtn="estadoBtn"
            />
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
// import { IonContent, IonPage } from "@ionic/vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import btnAction from "../components/generales/btnAction.vue";
import { useI18n } from "vue-i18n";
import { mapMutations, mapState } from "vuex";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "nuevoDestinatarioDisclaimer",
  components: {
    headerCerrar,
    btnAction,
    screen_width_cont,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["menuSpDesde", "widthScreen"]),

    estadoBtn() {
      let aux = "active";
      // if(!this.errorSimulador){
      //   aux = "active";
      // }
      return aux;
    },
  },
  methods: {
    ...mapMutations(["setshowSpinner"]),


    cerrarModal() {
      // console.log(this.menuSpDesde);
      this.$router.push({ name: this.menuSpDesde.desde, replace: true });
    },
    continuarRegistroDestinatario() {
      this.setshowSpinner(false);
      this.$router.push({ name: "nuevoDestinatarioForm", replace: true });
    },
  },
};
</script>

<style>
.newDestinaDisclContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 100vh;
}
.newDestinaDisclModal {
  background: #ffffff;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1.6875rem 1.1rem 3.125rem 1.1rem;
}
.newDestinaDisclHeader {
  margin-top: 0;
}
.newDestinaDisclTitle {
  text-align: center;
  color: #801b1c;
}
.newDestinaDisclText {
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px; */
  text-align: center;
  /* letter-spacing: 0.5px; */
  color: #787878;
  margin-top: 2.5rem;
}
.newDestinaDisclBtn {
  margin-top: 2.8125rem;
}
</style>